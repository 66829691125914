import React from 'react'
import Posts from '../components/Posts'
import Banner from '../components/Banner'
import Map from '../components/Map'
import Image1 from '../images/bottom.png';
import { useTranslation } from 'react-i18next';
import Mapkmz from '../components/MapKmz';



const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Banner />
      <Posts />
      {/* <div className='map'>
        <Map lat={29.668526529295963} lng={80.4284699963745} />
      </div> */}
      {/* map kmz for lekam */}
      {/* <div style={{ width: '100%', height: '400px' }} className='map'>
        <iframe src="https://www.google.com/maps/d/embed?mid=1Vd45kJpLZBzP7hIyN-M56WApBTLyREof&ehbc=2E312F" width="100%" height="400"></iframe>
      </div> */}
      <Mapkmz />
      {/* <div className='bottom-img'>
        <img src={Image1} alt="Image 1"/>
      </div> */}
   
    </>
  )
}

export default Home

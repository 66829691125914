import React from 'react'
import { FaAddressBook, FaBox, FaBriefcase, FaCalendarAlt, FaChartBar, FaEllipsisH, FaFrown, FaMap, FaStickyNote } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import '../styles/dashboard.css';
import { useTranslation } from 'react-i18next';


const CreateButtons = () => {
  const id = 1
  const { t } = useTranslation();

  return (
    <section className="dashs">
        <div className="container dashs__container">
          <Link key={id} to={"/product"} className='dash'>
            <div className="icon-container-dash"><FaBox/></div>
            <div className="dash__info">
            <h4>{t('product')}</h4>
            </div>
              </Link>
              <Link key={id} to={"/festival"} className='dash'>
            <div className="icon-container-dash"><FaCalendarAlt/></div>
            <div className="dash__info">
              <h4>{t('festival')}</h4>
            </div>
              </Link>
              <Link key={id} to={"/destination"} className='dash'>
            <div className="icon-container-dash"><FaMap/></div>
            <div className="dash__info">
              <h4>{t('destination')}</h4>
            </div>
              </Link>
              <Link key={id} to={"/business"} className='dash'>
            <div className="icon-container-dash"><FaBriefcase/></div>
            <div className="dash__info">
              <h4>{t('business')}</h4>
            </div>
              </Link>
              <Link key={id} to={"/contact"} className='dash'>
            <div className="icon-container-dash"><FaAddressBook /></div> 
            <div className="dash__info">
              <h4>{t('contact')}</h4>
            </div>
              </Link>
              <Link key={id} to={"/complain"} className='dash'>
            <div className="icon-container-dash"><FaFrown/></div>
            <div className="dash__info">
              <h4>{t('complaint')}</h4>
            </div>
              </Link>
              {/* <Link key={id} to={"/graph"} className='dash'>
            <div className="icon-container-dash"><FaChartBar/></div>
            <div className="dash__info">
              <h4>{t('stat')}</h4>
            </div>
              </Link> */}
              <Link key={id} to={"/note"} className='dash'>
            <div className="icon-container-dash"><FaStickyNote/></div>
            <div className="dash__info">
              <h4>{t('note')}</h4>
            </div>
              </Link>
              {/* <Link key={id} to={`/posts/users/${id}`} className='dash'>
                <div className="icon-container-dash"><FaEllipsisH /></div>
            <div className="dash__info">
              <h4>Others</h4>
            </div>
          </Link> */}
        </div> 
  </section>
  )
}

export default CreateButtons
